<template>
  <div class="manifest_table_out_div">
    <ManifestSearchBar id="ManifestSearchBar" @edit="edit" @search="searchList" :multiple-selection="multipleSelection" :type="search_completionstatus"></ManifestSearchBar>
    <ShManifestTable
        :type="search_completionstatus"
        :curr-page="currPage"
        :page-size="pageSize"
        :total-page="totalPage"
        :total-count="totalCount"
        :table-header="tableHeaders"
        :table-data="tableData"
        :data-loading="dataLoading"
        @fromChild="getSelection"
        @refresh="getList"
        @edit="edit"
        @nextPage="nextPage"
        @pageSizeChange="pageSizeChange"
        ref="ShManifestTable">
    </ShManifestTable>
  </div>
</template>

<script>
import mixin from '@/mixin/manifest/sh/manifestTable'

export default {
  mixins: [mixin],
  name: "SentTable",
  data() {
    return {
      search_completionstatus: 2, //搜索的舱单状态
    }
  },
}
</script>

<style lang="stylus" scoped>

</style>
