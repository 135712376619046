<!-- 上传文件/文件夹简易组件 -->
<template>
    <div>
        <div style="display: flex; justify-content: space-between">
            <div>
                <el-button @click="handleUploadFile" type="primary" size="small">
                    上传文件
                </el-button>
                <el-button @click="handleUploadFolder" type="warning" size="small">
                    上传文件夹(展开)
                </el-button>
                <input
                    type="file"
                    style="width: 0"
                    webkitdirectory
                    multiple
                    @change="handleUploadChange"
                    ref="folderRef"
                />
                <input
                    type="file"
                    style="width: 0"
                    @change="handleUploadChange"
                    ref="fileRef"
                />
            </div>
            <div>
                <!-- <el-button @click="handleTest">测试压缩包</el-button> -->
                <el-button type="danger" @click="handleClearAll" size="small">
                    清空
                </el-button>
            </div>
        </div>
        <el-table
            ref="tableRef"
            border
            :data="fileList"
            style="margin-top: 10px"
            @drop.native.prevent="handleDrop"
            @dragenter.native.prevent="handleDragenter"
            height="400"
        >
            <template #empty>
                <p v-if="dragActive">释放鼠标</p>
                <p v-else>
                    将
                    <span class="font-bold">文件</span>
                    或
                    <span class="font-bold">文件夹</span>
                    拖拽 到此处上传
                </p>
            </template>
            <el-table-column
                align="center"
                label="序号"
                type="index"
                width="80"
            ></el-table-column>
            <el-table-column
                align="center"
                label="文件名"
                prop="name"
            ></el-table-column>
            <el-table-column align="center" label="文件大小" prop="size">
                <template slot-scope="scope">
                    {{ getFileSize(scope.row.size) }}
                </template>
            </el-table-column>
            <el-table-column
                align="center"
                label="上传时间"
                prop="time"
            ></el-table-column>
            <el-table-column align="center" label="操作" prop="op">
                <template slot-scope="scope">
                    <el-button
                        type="danger"
                        size="mini"
                        @click="fileList.splice(scope.$index, 1)"
                    >
                        删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>
<script>
import JSZip from "jszip";
export default {
    name: "UploadFileAndFolder",
    data: () => {
        return {
            // 文件列表
            fileList: [],
            // 是否拖拽
            dragActive: false,
            zip:""
        };
    },
    methods: {
        // 获取文件夹下文件的entries
        readEntriesAsync(reader) {
            return new Promise((resolve) => {
                reader.readEntries((results) => {
                    resolve(results);
                });
            });
        },

        // 将文件entries变成file
        fileEntriesToFiles(entrie) {
            return new Promise((resolve) => {
                entrie.file((file) => {
                    resolve(file);
                });
            });
        },

        // 处理拖拽文件（夹）添加
        async handleFileFolderAdd(items) {
            for (let j = 0; j < items.length; j++) {
                const item = items[j];
                const fileEntry = await item.webkitGetAsEntry();
                // 当次文件夹文件大小
                let totalSize = 0;

                // 如果是文件夹类型
                if (fileEntry.isDirectory) {
                    // 文件夹类型，递归处理
                    const reader = fileEntry.createReader();
                    // 当前需要添加的文件夹
                    let curNeedAddFolder = {
                        name: fileEntry.name,
                        size: 0,
                        time: new Date().toLocaleString(),
                    };
                    // 是否是根目录
                    let isRoot = true;
                    // 根目录下初级结构的entries
                    let rootEntries = [];
                    const readEntries = async (reader) => {
                        return new Promise(async (resolve, reject) => {
                            const readEntriesRecursive = async (reader) => {
                                try {
                                    const results = await this.readEntriesAsync(
                                        reader,
                                    );
                                    if (isRoot) {
                                        rootEntries = results;
                                        isRoot = false;
                                    }
                                    for (let i = 0; i < results.length; i++) {
                                        const curFileEntry = results[i];
                                        if (curFileEntry.isFile) {
                                            const file =
                                                await this.fileEntriesToFiles(
                                                    curFileEntry,
                                                );
                                            totalSize += file.size;
                                        } else {
                                            await readEntries(
                                                curFileEntry.createReader(),
                                            );
                                        }
                                    }
                                    resolve(totalSize); // 当循环递归完成后，解析 Promise，并传递 totalSize
                                } catch (e) {
                                    console.error(e, "e");
                                    reject(e);
                                }
                            };
                            await readEntriesRecursive(reader);
                        });
                    };
                    readEntries(reader).then((totalSize) => {
                        this.fileList.push({
                            ...curNeedAddFolder,
                            size: totalSize,
                            fileEntry: rootEntries,
                        });
                    });
                }
                // 如果是文件类型
                else if (fileEntry.isFile) {
                    fileEntry.file((file) => {
                        this.handleFileAdd([file]);
                    });
                }
            }
        },
        // 处理文件添加
        handleFileAdd(files) {
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                Object.assign(file, { time: new Date().toLocaleString() });
                const fileNameList = this.fileList.map((item) => item.name);
                if (fileNameList.includes(file.name)) {
                    this.$message.error(`文件名${file.name}重复`);
                } else {
                    this.fileList.push(file);
                }
            }
        },

        // 文件值改变
        handleUploadChange(event) {
            let files = event.target.files;
            this.handleFileAdd(files);
        },

        // 上传文件
        handleUploadFile() {
            this.$refs.fileRef.click();
        },

        // 上传文件夹
        handleUploadFolder() {
            this.$refs.folderRef.click();
        },

        // 获得文件大小
        getFileSize(size) {
            let initNum = 0;
            const unit = ["Bytes", "KB", "MB", "GB", "TB"];
            const returnValFunc = () => {
                return size.toFixed(2) + " " + unit[initNum];
            };
            if (size <= 1024) {
                return returnValFunc();
            }
            // size/=1000;
            while (size > 1024) {
                size /= 1024;
                initNum++;
            }
            return returnValFunc();
        },

        /**
         * 处理拖拽
         *
         */

        preventDefault(e) {
            e.preventDefault();
            e.stopPropagation();
        },
        // 阻止默认行为
        handlePreventDefault() {
            ["dragenter", "dragover", "dragleave", "drop"].forEach((event) => {
                this.$refs.tableRef.$el.addEventListener(
                    event,
                    this.preventDefault,
                    false,
                );
            });
        },
        // 拖拽鼠标放下
        handleDrop(e) {
            this.dragActive = false;
            let items = e.dataTransfer.items;
            this.handleFileFolderAdd(items);
        },
        // 拖拽鼠标进入
        handleDragenter() {
            this.dragActive = true;
        },
        // 清空
        handleClearAll() {
            this.fileList = [];
        },

         // 定义一个函数来递归地添加文件和子文件夹到 ZIP
         addFolderToZip(folderPath, folder) {
            return new Promise((resolve, reject) => {
                const handleFolderToZip = async (folderPath, folder) => {
                    try {
                        // 获取文件夹中的文件和子文件夹列表
                        for (let i = 0; i < folder.length; i++) {
                            const item = folder[i];
                            if (item.isFile) {
                                // 如果是文件，则将文件添加到 ZIP
                                const file =
                                    await this.fileEntriesToFiles(
                                        item,
                                    );
                                this.zip.file(
                                    folderPath + "/" + file.name,
                                    file,
                                );
                            } else if (item.isDirectory) {
                                // 如果是子文件夹，则递归调用该函数
                                this.zip.folder(folderPath + "/" + item.name);
                                // 对文件夹内容进行解析
                                const results =
                                    await this.readEntriesAsync(
                                        item.createReader(),
                                    );
                                await this.addFolderToZip(
                                    folderPath + "/" + item.name,
                                    results,
                                );
                            }
                        }
                        resolve();
                    } catch (e) {
                        reject(e);
                    }
                };
                handleFolderToZip(folderPath, folder);
            });
        },
        // 进行列表压缩的函数
        handleZipFileList(fileList) {
            return new Promise(async (resolve, reject) => {
                try {
                    this.zip = new JSZip()
                    for (let i = 0; i < fileList.length; i++) {
                        const file = fileList[i];
                        // 如果是普通文件
                        if (!file.fileEntry || file.isFile) {
                            // 如果是文件，则将文件添加到 ZIP
                            // 生成blob对象，避免图片压缩出问题
                            const blob = new Blob([file], { type: file.type });
                            // 需要加上binary:true，否则微软自带的office可能会有展示bug
                            this.zip.file(file.name, blob, { binary: true });
                        } else {
                            // 文件夹类型的
                            // 如果是文件夹，则递归调用该函数
                            this.zip.folder(file.name);
                            await this.addFolderToZip(
                                file.name,
                                file.fileEntry,
                            );
                        }
                    }
                    resolve(this.zip);
                } catch (e) {
                    reject(e);
                }
            });
        },

         // 处理单个列表
         handleSingleFile() {
            return new Promise(async (resolve, reject) => {
                try {
                    this.zip = new JSZip();
                    // 列表压缩,值都在this.zip中了
                    await this.handleZipFileList(this.fileList);
                    resolve(this.zip);
                } catch (e) {
                    reject(e);
                }
            });
        },



    },
    mounted() {
        this.handlePreventDefault();
        this.fileList = [];
    },
};
</script>
<style scoped>
.font-bold {
    font-weight: bold;
}
</style>
