<template>
    <ShipDialog
        :visible.sync="visible"
        title="发送资料"
        custom-class="shManifest-file-send-dialog"
        showTab="SH"
        :needImg="false"
        top="5vh"
        @close="close"
    >
        <el-form ref="extraFormRef" :rules="formRules" :model="extraInfo">
            <el-form-item label="备注" prop="remark">
                <el-input
                    class="remarkText"
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 4 }"
                    placeholder="请输入内容"
                    maxlength="50"
                    show-word-limit
                    v-model="extraInfo.remark"
                ></el-input>
            </el-form-item>
            <el-form-item>
               <span>快捷备注：</span>
               <el-checkbox-group v-model="checkList" @change="updateRemark">
               <el-checkbox label="有vgm"></el-checkbox>
               <el-checkbox label="等通知"></el-checkbox>
               <el-checkbox label="紧急"></el-checkbox>
               <el-checkbox label="危险品"></el-checkbox>
               </el-checkbox-group>
            </el-form-item>
        </el-form>
        <el-tabs
            type="border-card"
            v-model="activeFileTab"
            v-if="mode === 'splice'"
        >
            <el-tab-pane
                v-for="item in Number(boxNumber)"
                :key="item"
                :name="item"
                :label="`拼箱${item}`"
            >
                <UploadFileAndFolder
                    :ref="'uploadFileAndFolderRef' + item"
                ></UploadFileAndFolder>
            </el-tab-pane>
        </el-tabs>
        <UploadFileAndFolder
            ref="uploadFileAndFolderRef"
            v-else
        ></UploadFileAndFolder>
        <DialogFooter
            confrimText="提交"
            cancelText="重置"
            showTab="SH"
            :loading="btnLoading"
            @cancel="handleReset"
            @confirm="handleSubmit"
        ></DialogFooter>
    </ShipDialog>
</template>

<script>
import JSZip, { files } from "jszip";
import { sendShManifestExtraInfo } from "@/api/sh-manifest";
import moment from "moment";
import UploadFileAndFolder from "@/components/common/UploadFileAndFolder.vue";
import VesselSelect from "@/components/common/VesselSelect.vue";
import ShipAgentSelect from "@/components/common/ShipAgentSelect.vue";
import ShipCompanySelect from "@/components/common/ShipCompanySelect.vue";
import ShipDialog from "@/components/layout/ShipDialog.vue";
import DialogFooter from "@/components/layout/DialogFooter.vue";

const formRules = {
    scompany: [{ required: true, message: "船公司必填" }],
};

export default {
    name: "SHManifestFileSend",
    components: {
        UploadFileAndFolder,
        VesselSelect,
        ShipAgentSelect,
        ShipCompanySelect,
        ShipDialog,
        DialogFooter,
    },
    emits: ["confirm"],
    data() {
        return {
            mode: "whole", //whole-整柜、splice-拼箱
            formRules,
            visible: false,
            uuid: "",
            btnLoading: false,
            checkList:[],
            extraInfo: {
                remark: "",
                files:null,
            },
            boxNumber: 1,
            activeFileTab: 1, // 分票选中的tab
        };
    },
    methods: {
        updateRemark() {
            const selectedRemarks = this.checkList.join(';');
            if (this.extraInfo.remark.trim() === '') {
                this.extraInfo.remark = selectedRemarks + ' | ';
            } else { 
                const cleanedRemark = this.extraInfo.remark.replace(/.*\|/, '');
                this.extraInfo.remark = selectedRemarks + ' |' +cleanedRemark;
            }
    },
        // submitExtraInfo() {
        //     const { billno, remark, ...restExtraInfo } = this.extraInfo;
        //     sendShManifestExtraInfo({
        //         billno: billno,
        //         subject: `(${
        //             this.mode === "splice" ? "拼箱" : "整柜"
        //         })${billno} 备注：${remark}`,
        //         uuid: this.uuid,
        //         ...restExtraInfo,
        //     })
        //         .then((res) => {
        //             this.$message.success("资料发送成功！");
        //             this.handleReset();
        //             this.$emit("confirm");
        //             this.close();
        //         })
        //         .finally(() => {
        //             this.btnLoading = false;
        //         });
        // },
        uploadFileFunc(zip, name) {
            zip.generateAsync({
                type: "blob",
            })
                .then((content) => {
                    const fils = new File([content], name, {
                        type: "zip",
                    });
                    this.extraInfo.files = fils;
                    sendShManifestExtraInfo(this.extraInfo)
                        .then(({ data }) => {
                            if (data.status) {
                                this.$message.success("资料发送成功");
                                this.handleReset();
                                this.clearFrom();
                                this.close();
                                this.btnLoading = false;
                            } else {
                                const msg = data.message || "资料发送失败";
                                this.$message.error(msg);
                                this.btnLoading = false;
                            }
                        })
                        .catch(() => {
                            this.btnLoading = false;
                        });
                })
                .catch(() => {
                    this.btnLoading = false;
                });
        },
        // 压缩上传
        async zipAndUploadFull() {
            if (!this.$refs.uploadFileAndFolderRef.fileList.length) {
                this.$message.error("请上传文件！");
            } else {
                this.btnLoading = true;
                const zip =
                    await this.$refs.uploadFileAndFolderRef.handleZipFileList(this.$refs.uploadFileAndFolderRef.fileList);
                const name = `舱单(整柜)_${
                    this.extraInfo.billno
                }_${moment().format("YYYYMMDDHHmmss")}.zip`;
                this.uploadFileFunc(zip, name);
            }
        },
        async zipAndUploadSplice() {
            const zipList = new JSZip();
            const fileLenArr = [];
            for (let i = 0; i < this.boxNumber; i++) {
                const boxSerialNum = i + 1;
                const curRefStr = `uploadFileAndFolderRef${boxSerialNum}`;
                const curBoxFileLen = this.$refs[curRefStr][0].fileList.length;
                if (!curBoxFileLen) {
                    // 文件为空则不执行后面的逻辑
                    fileLenArr.push(0);
                    continue;
                } else {
                    fileLenArr.push(curBoxFileLen);
                }
                let zipSingle = await this.$refs[
                    curRefStr
                ][0].handleSingleFile();
                // 当前需要列表解析的zip
                const content = await zipSingle.generateAsync({
                    type: "blob",
                });
                const fils = new File([content], `拼箱${boxSerialNum}.zip`, {
                    type: "zip",
                });
                zipList.file(`拼箱${boxSerialNum}.zip`, fils);
            }
            const allBoxFileEmpty = fileLenArr.every((len) => len === 0);
            if (allBoxFileEmpty) {
                this.$message.error("请上传文件！");
            } else {
                this.btnLoading = true;
                const name = `舱单(拼箱)_${
                    this.extraInfo.billno
                }_${moment().format("YYYYMMDDHHmmss")}.zip`;
                this.uploadFileFunc(zipList, name);
            }
        },
        handleSubmit() {
            const that = this;
            this.$refs.extraFormRef.validate((valid) => {
                if (valid && that.mode === "whole") {
                    that.zipAndUploadFull();
                }
                if (valid && that.mode === "splice") {
                    that.zipAndUploadSplice();
                }
            });
        },
        handleReset() {
            this.$refs.extraFormRef.resetFields();
        },
        clearFrom() {
            this.checkList = [];
            this.$refs.uploadFileAndFolderRef.handleClearAll();
        },
        close() {
            this.visible = false;
        },
        open(openMode) {
            if (openMode) {
                this.mode = openMode;
            }
            this.visible = true;
        },
    },
};
</script>

<style lang="stylus">
.shManifest-file-send-dialog{
    .el-input-number .el-input__inner{
        text-align: left;
    }
    .el-dialog__body{
        padding-top: 8px;
        padding-bottom: 24px;
    }
}

.remarkText .el-textarea__inner {
    min-height: 80px; 
    font-size: 18px; 
  }
</style>
