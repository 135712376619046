<template>
    <el-select v-model="curShipCompany" placeholder="请选择船公司" filterable style="width:100%">
        <el-option
            v-for="item in totalShipCompany"
            :key="item.value"
            :label="item.label"
            :value="item.label"
        ></el-option>
    </el-select>
</template>

<script>
export default {
    name: 'ShipCompanySelect',
    model: {
        prop: 'shipcompany',
        event: 'change'
    },
    props: {
        shipcompany: String
    },
    computed: {
        curShipCompany: {
            get() { 
                return this.shipcompany
            },
            set(val) {
                this.$emit('change',val)
            }
        },
        totalShipCompany() { 
            return this.$store.state.txtdata.shipCompany
        }
    },
    mounted() { 
        if (!this.totalShipCompany.length) { 
            this.$store.dispatch('txtdata/updateShipCompany')
        }
    }
}
</script>