<template>
    <el-autocomplete
        clearable
        @clear="blurForBug()"
        :value="vesselname"
        @input="checkInputVesselename"
        @change="checkInputVesselename2(vesselname)"
        :fetch-suggestions="shipSearch"
        placeholder="请输入船名"
        :trigger-on-focus="false"
        @select="handleShipSelect"
        class="vessels-select"
    >
        <template slot-scope="{ item }">
            <div
                style="
                    font-size: 12px;
                    display: block;
                    overflow: hidden;
                    word-break: keep-all;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                "
                class="name"
            >
                {{ item.value }}
            </div>
        </template>
    </el-autocomplete>
</template>
<script>
import {
    setRightForrmatSpace2,
} from "@/utils/validate";
import { searchShip } from "@/api/boxQuery";
export default {
    name: 'VesselSelect',
    model: {
        prop: 'vesselname',
        event: 'change'
    },
    props: {
        vesselname: String,
        showVoyageNo: Boolean,
    },
    data() { 
        return {
            totalshipList: [], //全部船名航次
        }
    },
    methods: {
        blurForBug() {
            document.activeElement.blur();
        },
        shipFilter(queryString) {
            return (restaurant) => {
                return (
                    restaurant.value.indexOf(queryString.toUpperCase()) != -1
                );
            };
        },
        checkInputVesselename(val) {
            let newVal = val
            if (newVal) {
                newVal = setRightForrmatSpace2(val)
                this.$emit('change', newVal)
            } else { 
                this.$emit('change', '')
            }
            if (newVal.length >= 3) {
                searchShip({ vesselsName: newVal })
                    .then(({ data }) => {
                        this.totalshipList = data.data.map((item) => ({
                            value: this.showVoyageNo ? `${item.s}/${item.v}` : item.s,
                        }));
                    })
                    .catch(() => {
                        this.totalshipList = [];
                    });
            } else if (newVal.length < 3) {
                this.totalshipList = [];
            }
        },
        checkInputVesselename2(val) {
            if (val) {
                var len = val.length - 1;
                if (val[len] == "." || val[len] == "/") {
                    this.$emit('change',val.substring(0, len))
                }
            }
        },
        //全部船名航次搜索框带输入建议
        shipSearch(queryString, cb) {
            var restaurants = this.totalshipList || [];
            var results = queryString
                ? restaurants.filter(this.shipFilter(queryString))
                : restaurants;
            // 调用 callback 返回建议列表的数据
            cb(results);
        },
        handleShipSelect(item) {
            var selectShip = item.value.split("/");
            this.$emit('change', selectShip[0])
            if (this.showVoyageNo) { 
                this.$emit('voyageNoChange',selectShip[1])
            }
        },
    }
}
</script>

<style lang="stylus">
.vessels-select{
    width: 100%;
}
</style>