<template>
  <div class="the_table">
    <el-table ref="multipleTable" border stripe :data="tableData" tooltip-effect="dark" :cell-class-name="timeCellClass"
              @selection-change="handleSelectionChange" :header-cell-style="{
            background: '#d4d9e1',
            color: '#606278',}" :cell-style="{'text-align':'center'}"
              v-loading="dataLoading"
              element-loading-text="数据加载中"
              element-loading-background="rgba(255, 255, 255, 0.5)"
              :max-height="tableHeight">
      <slot name="first">
        <el-table-column type="selection" width="55" fixed="left" align="center">
        </el-table-column>
      </slot>
      <el-table-column
          type="index"
          fixed="left"
          width="50">
      </el-table-column>
      <el-table-column v-for="item in tableHeader" :key="item.prop" :prop="item.prop" :label="item.label" align="left"
                       :header-align="item.align?item.align:'center'"
                       :width="item.width?item.width:'auto'" :fixed="item.fixed">
        <template slot-scope="scope">
          <span v-if="item.prop === 'billno'" :class="item.prop">{{
              scope.row['pbillno'] ? scope.row['pbillno'] : scope.row[item.prop]
            }}
          <img
              src="@/assets/icon/copy.svg"
              v-clipboard:copy="copyDetail(scope.row)"
              v-clipboard:success="copySucc"
          />
          </span>
          <span v-else-if="item.prop === 'operator'">{{scope.row.createUserName?scope.row.createUserName:scope.row.createUserName}}</span>
          <div v-else-if="item.prop==='billNos'">
            <p class="good_billno" v-for="billno in getGoodsBillNos(scope.row.goods) " :key="billno">
              {{ billno }}
              <img
                  src="@/assets/icon/copy.svg"
                  v-clipboard:copy="billno"
                  v-clipboard:success="copySucc"
              />
            </p>

          </div>
          <div v-else-if="item.prop==='ctnNos'">
            <p class="good_ctnno" v-for="good in scope.row.goods" :key="good.id">
              {{ good.ctnno }}
            </p>
          </div>
          <span v-else-if="item.prop==='remark'"
                :class="[item.prop,scope.row[item.prop]?'table_link_text_bold_black':'']"
                @click="!scope.row[item.prop]?addRemark(scope.row):''"
                class="table_link_text_bold"
          ><i @click="addRemark(scope.row)" v-if="scope.row[item.prop]" class="el-icon-edit"></i>
            {{ scope.row[item.prop] ? scope.row[item.prop] : '添加备注' }}</span>
          <span v-else-if="item.prop === 'shipAgentBillStatusStr'" :class="item.prop"
          >
<!--            <span-->
            <!--              v-if="scope.row['shipAgentBillStatus'] && scope.row['shipAgentBillStatus']===0">{{-->
            <!--              scope.row[item.prop]-->
            <!--            }}</span>-->
            <!--            <el-tooltip v-else class="item" effect="dark" :content="scope.row[item.prop]" placement="top">-->
            <img v-if="scope.row['shipAgentBillStatus'] && scope.row['shipAgentBillStatus']!==0"
                 :src="(scope.row['shipAgentBillStatus'] && scope.row['shipAgentBillStatus']===1)? receiptSuccessImg:receiptFailImg"
            />
            {{ scope.row[item.prop] }}
            <!--            </el-tooltip>-->
          </span>
          <span v-else-if="item.prop === 'customsReceiptStatusStr'" :class="item.prop"
          >
<!--            <span-->
            <!--              v-if="scope.row['customsReceiptStatus'] && scope.row['customsReceiptStatus']===0">{{-->
            <!--              scope.row[item.prop]-->
            <!--            }}</span>-->
            <!--            <el-tooltip v-else class="item" effect="dark" :content="scope.row[item.prop]" placement="top">-->
          <img v-if="scope.row['customsReceiptStatus'] && scope.row['customsReceiptStatus']!==0"
               :src="(scope.row['customsReceiptStatus'] && scope.row['customsReceiptStatus']===1)? receiptSuccessImg:receiptFailImg"
          />
            {{ scope.row[item.prop] }}
            <!--            </el-tooltip>-->
          </span>
          <span v-else-if="item.prop === 'vgmReceiptMsg'" :class="item.prop"
          >
          <img v-if="scope.row['vgmReceiptStatus'] && scope.row['vgmReceiptStatus']!=0"
               :src="(scope.row['vgmReceiptStatus'] && scope.row['vgmReceiptStatus']==1)? receiptSuccessImg:receiptFailImg"
          />
            {{ scope.row[item.prop] }}
          </span>
          <span
              v-else-if="item.prop.includes('date') ||item.prop.includes('Date') ||item.prop.includes('Time')||item.prop.includes('time')"
              :class="[item.prop]">{{
              formatTime(item.prop === 'ctnApplyBeginTime' || item.prop === 'ctnApplyEndTime' ? scope.row['shVessels'] ? scope.row['shVessels'][item.prop] : '' : scope.row[item.prop])
            }}</span>
          <span v-else-if="item.prop==='loadingReplayStatusStr'">
            <el-button type="text" :disabled="scope.row['completionstatus']!==2" v-if="!scope.row[item.prop]"
                       class="loadingReplayRefresh" @click="operator('loading_replay',scope.row)">刷新回执<i
                class="el-icon-refresh"></i></el-button>
            <span v-else>{{ scope.row[item.prop] }}</span>
          </span>
          <span v-else-if="item.prop==='completionstatus'">
            {{
              scope.row[item.prop] === 2 ? '已发送' : scope.row[item.prop] === -2 ? '已删改' : scope.row[item.prop] === -3 ? '柜台删单'
                  : scope.row[item.prop] === 1 ? '待发送' : scope.row[item.prop] === -1 ? '垃圾箱' : '草稿箱'
            }}
          </span>
          <span v-else-if="item.prop==='isCustomerApplyFinish'">
            {{
              scope.row[item.prop] === 2 ? '已处理' : scope.row[item.prop] === 1 ? '处理中' : scope.row[item.prop] === 0 ? '待处理' : ''
            }}
          </span>
          <span v-else>
            {{ scope.row[item.prop] }}</span>
        </template>
      </el-table-column>
      <slot name="todo">
        <el-table-column fixed="right" label="操作" width="160">
          <template slot-scope="scope">
            <div>
              <!--    用户通用操作          -->
              <span class="update_span"
                    @click="operator('look',scope.row)">查看</span>
              <span class="update_span"
                    v-if="[0,1].includes(type)||(scope.row.completionstatus===2&&scope.row.shipAgentBillStatus!==0&&!isAfterToday(scope.row['shVessels']?scope.row['shVessels']['ctnApplyEndTime']:''))||(checkPer(['shManifest:agent_update']&&!isAfterToday(scope.row['shVessels']?scope.row['shVessels']['ctnApplyEndTime']:'')))"
                    @click="operator('edit',scope.row)">修改</span>
<!--              <span class="update_span"-->
<!--                    v-if="[0].includes(type)"-->
<!--                    @click="downloadData(scope.row)">下载客户资料</span>      -->
              <span class="update_span"
                    v-if="(!scope.row.vgmId)&&scope.row.completionstatus===2&& checkPer(['VGM'])&&(!isAfterToday(scope.row['shVessels']?scope.row['shVessels']['ctnApplyEndTime']:''))"
                    @click="operator('vgm',scope.row)">发送VGM</span>
              <!--              <span class="delete_span" v-if="[0,1].includes(type)" @click="showDelete(scope.row)">删除</span>-->
              <!--  系统操作员操作            -->
              <span class="update_span" v-if="checkPer(['shManifest:agent_update'])
              &&[3].includes(type)&&scope.row.fcwStatusStr&&scope.row.fcwStatusStr.includes('00010')"
                    @click="operator('agent_update',scope.row)"
              >发送修改报文</span>
              <!--              <span class="update_span" v-if="checkPer(['shManifest:agent_delete'])-->
              <!--              &&[3].includes(type)&&scope.row.fcwStatusStr&&scope.row.fcwStatusStr.includes('00008')"-->
              <!--                    @click="operator('agent_delete',scope.row)"-->
              <!--              >发送删除报文</span>-->
              <span class="update_span"
                    v-if="checkPer(['shManifest:agent_direct_reissue'])
                    &&[2,3].includes(type)&&scope.row.shipAgentBillStatus&&scope.row.shipAgentBillStatus!==1
                    &&(scope.row.shipAgentBillStatusStr.includes('23103')
                    ||scope.row.shipAgentBillStatusStr.includes('21102')
                    ||scope.row.shipAgentBillStatusStr.includes('11104'))"
                    @click="operator('agent_direct_reissue',scope.row)">直接重发报文</span>
              <span class="update_span"
                    v-if="checkPer(['shManifest:agent_reissue'])
                    &&[2,3].includes(type)&&scope.row.shipAgentBillStatus&&scope.row.shipAgentBillStatus!==1
                    &&scope.row.fcwStatusStr&&scope.row.fcwStatusStr.includes('00008')"
                    @click="operator('agent_reissue',scope.row)">发送重发报文</span>
              <span class="update_span"
                    v-if="(scope.row.isProblemBill===1||[0,1].includes(scope.row.isCustomerApplyFinish))&&[3].includes(type)"
                    @click="operator('customerApplyFinish',scope.row)">标记为已处理</span>
            </div>
          </template>
        </el-table-column>
      </slot>
      <!--      <el-dialog title="提示" :visible.sync="deleteDialogVisible1" width="30%" append-to-body center>-->
      <!--        <span>你确认要申请撤销该舱单数据吗？</span>-->
      <!--        <span slot="footer" class="dialog-footer">-->
      <!--                <el-button @click="deleteDialogVisible1 = false">取 消</el-button>-->
      <!--                <el-button type="primary" @click="operator('delete')">确 定</el-button>-->
      <!--            </span>-->
      <!--      </el-dialog>-->
    </el-table>
    <div class="pagination_div">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total,sizes,prev, pager, next"
          :total="totalCount"
          :page-count="totalPage"
          :page-sizes="[50,100,200]"
          :page-size="pageSize"
          :current-page="currPage">
      </el-pagination>
    </div>
    <DeleteManifestDialog ref="DeleteManifestDialog" @refresh="refreshList">
    </DeleteManifestDialog>
    <LookDetailDialog ref="LookDetailDialog" v-if="lookDetailDialog"></LookDetailDialog>
    <!-- 弹窗, 修改备注 -->
    <remark-box-dialog
        :show-tab="showTab"
        ref="RemarkBoxDialog"
        @refreshDataList="refreshList"
    ></remark-box-dialog>
  </div>
</template>

<script>
import DeleteManifestDialog from "./DeleteManifestDialog.vue";
import LookDetailDialog from "./LookDetailDialog";
import ShanghaiChatDialog from "./ShanghaiChatDialog.vue";
import ReceiptSuccessImg from "@/assets/img/green.png";
import ReceiptFailImg from "@/assets/img/red.png";
import {mapGetters} from "vuex";
import {
  eleDelete,
  delectbillNo,
  agentLogin,
  agentDirectReissue,
  agentReissue,
  agentUpdate,
  agentCheck,
  agentSync, agentLoadingReplay, billIsDealFinish,
} from "@/api/shanghaiManifest";
import RemarkBoxDialog from "@/views/ShanghaiManifest/components/ManifestRemarkDialog.vue";
import moment from "moment";
import {saveAndReissue, saveAndResend} from "../../../api/shanghaiManifest";

export default {
  components: {
    RemarkBoxDialog,
    LookDetailDialog,
    DeleteManifestDialog,
    ShanghaiChatDialog
  },
  watch: {
    dataLoading(newVal) {
      return newVal;
    }
  },
  props: {
    tableHeader: {
      //列表标题
      type: Array,
      default: () => [],
    },
    tableData: {
      //列表数据
      type: Array,
      default: () => [],
    },
    type: {
      type: Number,
      default: 0, //0:草稿箱，1：待发送，2：已发送
    },
    currPage: {
      type: Number,
      default: 1,
    },
    totalPage: {
      type: Number,
      default: 10,
    },
    pageSize: {
      type: Number,
      default: 50,
    },
    totalCount: {
      type: Number,
      default: 10,
    },
    dataLoading: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      receiptSuccessImg: ReceiptSuccessImg,
      receiptFailImg: ReceiptFailImg,
      showTab: "Manifest",
      deleteDialogVisible1: false,
      isDraft: false,
      isWaitSend: false,
      isAlreadySend: false,
      lookDetailDialog: false,
      waitSend: false,
      alreadySend: false,
      AllPackage: 0,
      AllWeight: 0,
      AllVolume: 0,
      DeletetableData: [], //删除的表格数据
      DeleteID: "",
      UpdateManifest: false,
      multipleSelection: [], //多选中的信息
      goodsData: [],
      loginUserId: '',
      tableHeight: 500,
      topMsgBarHeight: 80,
    };
  },
  computed: {},
  mounted() {
    this.loginUserId = this.$store.state.user.userId;
    this.getTableHeight();
    //挂载window.onresize事件(动态设置table高度)
    let _this = this;
    window.onresize = () => {
      if (_this.resizeFlag) {
        clearTimeout(_this.resizeFlag);
      }
      _this.resizeFlag = setTimeout(() => {
        _this.getTableHeight();
        _this.resizeFlag = null;
      }, 100);
    };
  },
  methods: {
    //获取分提单号并去重
    getGoodsBillNos(arr) {
      let billNos = new Set();
      if (arr && arr.length > 0) {
        for (let i = 0; i < arr.length; i++) {
          billNos.add(arr[i].billno)
        }
      }
      return Array.from(billNos)
    },
    isAfterToday(time) {
      if (time && time.length > 0) {
        const date_now = moment().format("YYYY-MM-DD");
        const order_time = moment(time).format("YYYY-MM-DD");
        return moment(date_now).isAfter(order_time);
      } else {
        return false;
      }
    },
    //计算table高度(动态设置table高度)
    getTableHeight() {
      this.topMsgBarHeight = document.getElementById("ManifestSearchBar").offsetHeight + 180;
      this.$nextTick(() => {
        this.tableHeight = window.innerHeight - this.topMsgBarHeight;
      })
    },
    // 时间处理切割
    formatTime(time) {
      return time && time !== "-" ? time.slice(5, time.length - 3) : "-";
    },
    copySucc() {
      this.$message.success("复制成功");
    },
    handleSelectionChange(val) {
      console.log('选择项发生变化', val);
      this.multipleSelection = val;
      this.$emit('fromChild', this.multipleSelection);
    },
    operator(type, item) {//edit,delete,copy
      if (type === 'look') {
        this.lookDetailDialog = true;
        this.$nextTick(() => {
          this.$refs.LookDetailDialog.init(item);
        })
      } else if (type === 'agent_login') {
        this.agentLogin(item.id);
      } else if (type === 'agent_check') {
        this.agentCheck(item.id);
      } else if (type === 'agent_sync') {
        this.agentSync(item.id);
      } else if (type === 'agent_update') {
        this.agentUpdate(item.id);
      } else if (type === 'agent_reissue') {
        this.agentReissue(item.id);
      } else if (type === 'agent_direct_reissue') {
        this.agentDirectReissue(item.id);
      } else if (type === 'loading_replay') {
        this.getLoadingReplay(item.id);
      } else if (type === 'vgm') {
        this.createOrUpdateVGM(item)
      } else if (type === 'customerApplyFinish') {
        this.customerApplyFinish(item)
      }
      this.$emit(type, item);
    },
    customerApplyFinish(item) {
      let data = [item.id];
      billIsDealFinish(data).then((res) => {
        if (res.data.status) {
          this.$message.success(res.data.data);
        }
      })
    },
    createOrUpdateVGM(item) {
      let path = {
        title: item.vgmId ? "编辑" : '新建',
        id: item.vgmId ? item.vgmId : '',
        manifestDataId: item.id,
        pathName: 'CreateVGM'
      }
      this.$router.push({name: path.pathName, params: path})
    },
    agentLogin(id) {
      agentLogin({id: id}).then((res) => {
        if (res.data.status) {
          this.$message(res.data.data);
        }
      })
    },
    agentUpdate(id) {
      saveAndResend(id).then((res) => {
        if (res.data.status) {
          this.$message(res.data.data);
        }
      })
    },
    agentReissue(id) {
      saveAndReissue(id).then((res) => {
        if (res.data.status) {
          this.$message(res.data.data);
        }
      })
    },
    getLoadingReplay(id) {
      agentLoadingReplay(id).then((res) => {
        if (res.data.status) {
          this.$message.success(res.data.data);
          this.$emit('refresh');
        }
      })
    },
    agentDirectReissue(id) {
      agentDirectReissue(id).then((res) => {
        if (res.data.status) {
          this.$message.success("已重发，请等待回执")
        }
      })
    },
    agentCheck(id) {
      agentCheck({id: id}).then((res) => {
        if (res.data.status) {
          this.$message.success("校验成功")
        } else {
          this.$message.error(res.data.data.msg);

        }
      })
    },
    agentSync(id) {
      agentSync({id: id}).then((res) => {
        if (res.data.status) {
          this.$message.success("同步成功")
        }
      })
    },
    refreshList() {
      this.$emit('refresh')
    },
    showDelete(item) {
      //删除
      const deleteData = [];
      deleteData.push(item);
      this.$nextTick(() => {
        this.$refs.DeleteManifestDialog.init(deleteData);
      })
    },
    toReturnDelete(item) {//申请删除
      this.DeleteID = item.billno;
      this.deleteDialogVisible1 = true;
    },
    Delete() {
      let idlist = [this.DeleteID];
      eleDelete(idlist)
          .then((response) => {
            if (response.data.status == true) {
              this.$message({
                type: "success",
                message: "删除成功！"
              })
              this.$emit("queryDraft")
              this.$emit("queryWait")
            }
            this.$emit(type, item);
          })
    },
    Delete1() {
      delectbillNo({billno: this.DeleteID}).then((response) => {
        if (response.data.status == true) {
          this.$message({
            type: "success",
            message: "删除成功，已暂存在待发送！",
          });
        }
        this.$emit("queryAlready");
        this.deleteDialogVisible1 = false;
      });
    },
    lookit(item) {
      //查看详情
      this.lookDetailDialog = true;
      this.$nextTick(() => {
        this.$refs.LookDetailDialog.init(item);
      });
    },
    //添加备注
    addRemark(item = this.rows) {
      this.$nextTick(() => {
        this.$refs.RemarkBoxDialog.init({
          id: item.id,
          blno: item.billno,
          remark: item.remark
        });
      });
    },
    //下载客户资料
    downloadData(row) {
      const url = row.clientFileUrl
      const link = document.createElement('a');
      link.href = url;
      const fileName = url.substring(url.lastIndexOf('/') + 1);
      link.download = fileName; // 设置下载的文件名
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    handleSizeChange(val) {
      this.$emit("pageSizeChange", val);
    },
    handleCurrentChange(val) {
      this.$emit("nextPage", val);
    },
    copyDetail(entity) {
      return `提单号：${entity.billno}\n船名/航次：${entity.vesselname}/${entity.voyageno}\n船公司：${entity.scompany}\n船代：${entity.webtype}\n发送时间：${entity.sendTime}\n操作人员：${entity.createUserName}\n船代回执：${entity.shipAgentBillStatusStr ? entity.shipAgentBillStatusStr : ''}\n海关回执：${entity.customsReceiptStatusStr ? entity.customsReceiptStatusStr : ''}`
    },
    timeCellClass({row, column, rowIndex, columnIndex}) {
      if (column.property) {
        //进箱开始底色变绿色，进箱截止底色变橘色
        if (column.property === "ctnApplyBeginTime" && row.shVessels &&
            row.shVessels.ctnApplyBeginTime &&
            this.judgeTimeToday(row.shVessels.ctnApplyBeginTime)) {
          return "green_cell";
        }
        if (column.property === "ctnApplyEndTime" && row.shVessels && row.shVessels.ctnApplyEndTime) {
          if (this.judgeTimeToday(row.shVessels.ctnApplyEndTime)) {
            return "red_cell";
          } else if (this.judgeTimeClosing(row.shVessels.ctnApplyEndTime)) {
            return 'yellow_cell'
          }
        }
        // if (column.property === "shipAgentBillStatusStr" && row.shipAgentBillStatusStr) {
        //   if (row.shipAgentBillStatus === 1) {
        //     return "green_cell";
        //   } else if (row.shipAgentBillStatus === -1) {
        //     return "red_cell";
        //   }
        // }
        // if (column.property === "customsReceiptStatusStr" && row.customsReceiptStatusStr) {
        //   if (row.customsReceiptStatus === 1) {
        //     return "green_cell";
        //   } else {
        //     return "red_cell";
        //   }
        // }
        if (column.property === "billno" && row.billno) {
          if (this.type === 2) {
            if (row.completionstatus === -3) {
              return "yellow_cell";
            } else if (row.completionstatus === -2) {
              return "red_cell";
            }
          } else if (row.source === 1) {
            return "blue_cell";
          }
        }
        if (['billNos', 'ctnNos'].includes(column.property)) {
          return "goods_cell";
        }
      }
    },
    //判断时间接近指定的时间，暂定截止时间前一天
    judgeTimeClosing(time) {
      const day = moment(time).subtract(1, 'day').format('yyyy-MM-DD');//获取前一天
      return moment().isAfter(day);
    },
    //判断时间是否到达今天时间
    judgeTimeToday(time) {
      return moment().isAfter(time);
    },
  }

}

</script>

<style lang="stylus" scoped>
.update_span {
  color: blue;
  display: inline-block;
}

.update_span:nth-child(2n) {
  margin-right: 1rem;
  margin-left: 1rem;
}

.warn_span {
  color: var(--YELLOW-EDB518);
  display: inline-block;
  margin-right: 1.5rem;
}

.delete_span {
  color: red;
}

.the_table {
  flex: 1;
}

.pagination_div {
  text-align: center;
  margin: 1rem auto 0 !important;
}

.look_span,
.update_span,
.delete_span {
  cursor: pointer;
}

.look_span {
  color: green;
  display: inline-block;
  margin-right: 1.5rem;
}

.pagination_div {
  text-align: center;
  margin: 1rem;
}

.billno {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: bold;
  color: var(--BLUE-3366FF);

  img {
    margin-left: 2px;
    cursor: pointer;
  }
}


.shipAgentBillStatusStr {
  display: flex;
  justify-content: center;
  align-items: center;
  //font-weight: bold;
  margin-left: 2rem;
  color: var(--BLACK-2c2c2c);

  img {
    cursor: pointer;
    width: 2rem;
    height: 2rem;
    position: absolute;
    left: 1rem;
  }
}

.customsReceiptStatusStr {
  display: flex;
  justify-content: center;
  align-items: center;
  //font-weight: bold;
  margin-left: 2rem;
  color: var(--BLACK-2c2c2c);

  img {
    cursor: pointer;
    width: 2rem;
    height: 2rem;
    position: absolute;
    left: 1rem;
  }
}

.vgmReceiptMsg {
  display: flex;
  justify-content: center;
  align-items: center;
  //font-weight: bold;
  color: var(--BLACK-2c2c2c);

  img {
    cursor: pointer;
    width: 2rem;
    height: 2rem;
    position: absolute;
    left: 1rem;
  }
}

.vesselname {
  font-weight: bold;
}

.validation {
  color: var(--RED-F21137);
}

.total {
  text-align: center;
  display: block;
}

.table_link_text_bold {
  color: var(--BLUE-5493f4);
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  user-select: none;

  &:hover {
    opacity: 0.8;
  }
}

.table_link_text_bold_black {
  color: var(--BLACK-333);
  cursor: text;
  user-select: auto;

  i {
    cursor: pointer;
    user-select: none;
  }
}

.success {
  //color: var(--GREEN-1E9855);
  font-weight: bold
}

/deep/ .green_cell {
  background-color: var(--GREEN-03aa53) !important;
  color: var(--BLACK-333);
  //font-weight: bold;
}

/deep/ .blue_cell {
  background-color: var(--BLUE-d1e7ff) !important;
}

/deep/ .red_cell {
  background-color: var(--RED-F56C6C) !important;
  color: var(--BLACK-2c2c2c);
  //font-weight: bold;
  //color:white;
}

/deep/ .yellow_cell {
  background-color: var(--YELLOW-ffa000c7) !important;
  //font-weight: bold;
}

/deep/ .goods_cell {
  .cell {
    padding: 0 !important;
  }
}

.el-icon-edit {
  margin-right: 0.2rem;
}

/deep/ .el-table .el-table__cell {
  padding: 6px 0 !important;
}

.loadingReplayRefresh {
  color: var(--YELLOW-ed6a0c);
  cursor: pointer;
  text-align: center;

  i {
    color: black;
    margin-left: 0.2rem;
  }
}

.el-button {
  padding: 0;
}

.good_billno {
  margin: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 1rem;

  img {
    cursor: pointer;
    margin-left: 2px;
  }
}

.good_billno:not(:last-child) {
  border-bottom: 1px solid var(--GRAY-dee3ed);
  padding-bottom: 6px;
}

.good_billno:not(:first-child) {
  padding-top: 6px;
}

.good_ctnno {
  margin: 0;
}

.good_ctnno:not(:last-child) {
  border-bottom: 1px solid var(--GRAY-dee3ed);
  padding-bottom: 6px;
}

.good_ctnno:not(:first-child) {
  padding-top: 6px;
}
</style>
