<template>
  <div class="container">
    <NoticeTopBar :notice-id="83"></NoticeTopBar>
    <div class="input_div">
      <div v-if="type===0">
        <el-button type="primary" :size="size" @click="openManifestDialog()">
          新建舱单
        </el-button>
        <!--        <el-button type="primary" plain :size="size" @click="exportDialogVisible1 = true">列表模板导入/下载-->
        <!--        </el-button>-->
        <el-button type="primary" plain :size="size" @click="exportDialogVisible2 = true">单票模板导入/下载
        </el-button>
<!--        <el-button type="primary" :size="size" @click="handleFileSend('whole')">-->
<!--          资料委托录入-->
<!--        </el-button>-->
      </div>
      <div style="display:flex;">
        <div v-if="type===2" style="margin-right: 10px">
          <el-date-picker
              :size="size"
              v-model="dateRange"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              @change="setDate"
              :picker-options="pickerOptions">
          </el-date-picker>
        </div>
        <!--        <el-select class="el_select"-->
        <!--                   :size="size"-->
        <!--                   v-model="search.receipStatus"-->
        <!--                   placeholder="选择发送结果"-->
        <!--        >-->
        <!--          <el-option-->
        <!--              v-for="item in receipStatus"-->
        <!--              :key="item.value"-->
        <!--              :label="item.label"-->
        <!--              :value="item.value"-->
        <!--          ></el-option>-->
        <!--        </el-select>-->
        <el-select class="el_select"
                   :size="size"
                   filterable
                   clearable
                   v-model="search.showUserId"
                   placeholder="选择用户"
        >
          <el-option
              v-for="item in userList"
              :key="item.id"
              :label="item.label"
              :value="item.value"
          ></el-option>
        </el-select>
        <el-select class="el_select"
                   :size="size"
                   v-model="search.webtype"
                   placeholder="请选择船代"
                   clearable>
          <el-option
              v-for="item in shipAgent"
              :key="item.value"
              :label="item.label"
              :value="item.label + '|' + item.value"
          ></el-option>
        </el-select>
        <!--        <el-select class="el_select"-->
        <!--                   :size="size"-->
        <!--                   filterable-->
        <!--                   clearable-->
        <!--                   v-model="search.receiptStatus"-->
        <!--                   placeholder="选择舱单状态"-->
        <!--        >-->
        <!--          <el-option-->
        <!--              v-for="item in receiptStatus"-->
        <!--              :key="item.id"-->
        <!--              :label="item.label"-->
        <!--              :value="item.value"-->
        <!--          ></el-option>-->
        <!--        </el-select>-->
        <el-select class="inline-input "
                   v-model="search.vesselId"
                   :size="size"
                   remote
                   filterable
                   :remote-method="getVesselByKeyword"
                   @keyup.enter.native="go2Search"
                   placeholder="请输入船名/航次"
                   clearable>
          <el-option
              v-for="item in vesselOptions"
              :key="item.id"
              :label="item.label"
              :value="item.id">
          </el-option>
        </el-select>
        <el-input class="inline-input "
                  v-model.trim="search.ctnNo"
                  :size="size"
                  @keyup.enter.native="go2Search"
                  placeholder="请输入箱号"
                  clearable>
        </el-input>
        <div class="bill_search_div">
          <el-input class="inline-input "
                    v-model.trim="search.billno"
                    :size="size"
                    @keyup.enter.native="go2Search"
                    @input="formatBills"
                    placeholder="请输入提单号"
                    clearable
          >
          </el-input>
          <p class="multi_bill_div" @click="showMultiBillDialog">多票</p>
        </div>
        <el-button type="primary" :size="size" @click="go2Search">查询</el-button>
      </div>

      <div>
        <el-button type="warning" :size="size" @click="sendManifest" v-if="type===1" :loading="sendLoading">发送
        </el-button>
        <el-button type="primary" :size="size" plain @click="exportExcel()" v-if="[0,1].includes(type)"
                   :loading="exportLoading">导出
        </el-button>
        <!--        <el-button type="warning" :size="size" plain @click="agentSync()" v-if="type===2">刷新回执</el-button>-->
        <!--        <el-button type="success" :size="size" plain @click="agentDeclare()" v-if="type===2">船代申报</el-button>-->
        <el-button type="primary" :size="size" plain @click="exportHistoryExcel()" v-if="type===2"
                   :loading="exportLoading">导出记录
        </el-button>
        <el-button type="danger" :size="size" plain style="margin-right:1rem;" @click="toDelete(0)"
                   :loading="deleteLoading"
                   v-if="[0,1].includes(type)">删除
        </el-button>
        <!--        <el-button type="warning" :size="size" @click="toModify()"-->
        <!--                   icon="el-icon-warning-outline"-->
        <!--                   :loading="deleteLoading"-->
        <!--                   v-if="[2].includes(type)">修改舱单-->
        <!--        </el-button>-->

        <!-- <el-button type="warning" plain :size="size" style="margin-right:1rem;" @click="batchRefresh"
                   :loading="deleteLoading"
                   v-if="[2].includes(type)">批量刷新
        </el-button> -->
        <el-button type="warning" :size="size" style="margin-right:1rem;" @click="toDelete(2)"
                   icon="el-icon-warning-outline"
                   :loading="deleteLoading"
                   v-if="[2].includes(type)">柜台删单
        </el-button>
        <el-button type="danger" :size="size" style="margin-right:1rem;" @click="toDelete(1)"
                   icon="el-icon-warning-outline"
                   :loading="deleteLoading"
                   v-if="[2].includes(type)">删除舱单
        </el-button>
      </div>
    </div>
    <el-dialog title="列表模板导入/下载" :visible.sync="exportDialogVisible1" width="30%" center :modal="false">
      <div class="export_module">
        <p>可先下载模板按照格式填写后上传</p>
        <!--        <el-image :src="demoImg2[0]"-->
        <!--                  fit="cover"-->
        <!--                  :preview-src-list="demoImg2"-->
        <!--                  style="width: 100px; height: 100px">-->
        <!--        </el-image>-->
      </div>
      <el-upload style="margin:2rem auto;text-align:center;"
                 :file-list="fileList1" :auto-upload="false" class="upload-demo" drag action=""
                 :on-change="fileUpload1" multiple>
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      </el-upload>
      <span slot="footer" class="dialog-footer">
        <el-button @click="exportDialogVisible1 = false">取 消</el-button>
        <el-button type="primary" @click="toDownload1()">下载模板</el-button>
      </span>
    </el-dialog>
    <el-dialog title="单票模板导入/下载" :visible.sync="exportDialogVisible2" width="30%" center>
      <div class="export_module">
        <p>可先下载模板按照格式填写后上传</p>
        <!--        <el-image :src="demoImg2[0]"-->
        <!--                  fit="cover"-->
        <!--                  :preview-src-list="demoImg2"-->
        <!--                  style="width: 100px; height: 100px">-->
        <!--        </el-image>-->
      </div>
      <el-upload style="margin:2rem auto;text-align:center;"
                 :file-list="fileList2" :auto-upload="false" class="upload-demo" drag action=""
                 :on-change="fileUpload2" multiple>
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      </el-upload>
      <span slot="footer" class="dialog-footer">
        <el-button @click="exportDialogVisible2 = false">取 消</el-button>
        <el-button type="primary" @click="toDownload2()">下载模板</el-button>
      </span>
    </el-dialog>
    <DeleteManifestDialog ref="DeleteManifestDialog" @refresh="go2Search"
                          @deleteError="showErrorList">
    </DeleteManifestDialog>
    <DeleteManifestErrorDialog ref="DeleteManifestErrorDialog">
    </DeleteManifestErrorDialog>
    <MultiBillNoDialog ref="MultiBillNoDialog" @searchBills="searchBills"></MultiBillNoDialog>
    <file-send ref="fileSendRef" @confirm="go2Search"></file-send>
  </div>
</template>

<script>
import DeleteManifestDialog from "./DeleteManifestDialog.vue";
import DeleteManifestErrorDialog from "./DeleteManifestErrorDialog.vue";
import NoticeTopBar from "@/views/ShanghaiManifest/components/NoticeTopBar.vue";
import exportModuleImg1 from "@/assets/img/shanghaiManifest/sh_manifest_demo_1.png"
import exportModuleImg2 from "@/assets/img/shanghaiManifest/sh_manifest_demo_2.png"
import MultiBillNoDialog from "@/views/ShanghaiManifest/components/MultiBillNoDialog.vue";
import {
  agentSync,
  downloadExcelModule1, downloadExcelModule2,
  exportManifestHistoryExcel,
  exportShipExcel, getShipAgentList
  , importExcelModule1, importExcelModule2, agentLoadingReplay
} from "@/api/shanghaiManifest";
import moment from "moment";
import {sendHyManifestShHead} from "@/api/shanghaiManifest";
import {getUserAccountInfo} from "@/utils";
import {getAllCompanyStaffById} from "@/api/company";
import {getVesselByKeyword} from "@/api/ship";
import FileSend from "@/views/SHManifestSend/components/file-send.vue";

export default {
  name: "ManifestSearchBar",
  components: {FileSend, DeleteManifestDialog, DeleteManifestErrorDialog, NoticeTopBar, MultiBillNoDialog},
  props: {
    multipleSelection: {//选中的信息
      type: Array,
      default: () => []
    },
    type: {
      type: Number,
      default: 0//0:草稿箱，1：待发送，2：已发送
    }
  },
  data() {
    return {
      deleteLoading: false,
      sendLoading: false,
      exportLoading: false,
      size: 'medium',
      shipAgent: [],//船代
      userList: [],//企业用户可以筛选查看其他同事的单子
      companysOptions: [],//系统操作员可查看其他企业
      receiptStatus: [
        {
          label: '全部',
          value: null
        },
        {
          label: '船代处理中',
          value: 0
        },
        {
          label: '回执成功',
          value: 1
        },
        {
          label: '回执失败',
          value: -1
        },
      ],//舱单状态
      demoImg1: [exportModuleImg1],
      demoImg2: [exportModuleImg2],
      fileList1: [],
      fileList2: [],
      exportDialogVisible1: false,//列表模板下载
      exportDialogVisible2: false,//海管家模板下载
      search: {
        showUserId: '', //筛选相应用户
        showCompanyId: this.loginUserCompanyId, //筛选相应企业
        receiptStatus: null, //发送的舱单状态
        billno: '', //搜索的提单号
        ctnNo: '', //搜索的箱号
        vesselId: '', //搜索的船期ID
        vesselname: '', //搜索的船名
        voyageno: '', //搜索的航次
        startDate: '',
        endDate: ''
      },
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      dateRange: [],//日期时间范围
      loginUserId: '',
      loginUserCompanyId: '',
      vesselOptions: [],
    }
  },
  mounted() {
    this.getShipAgentList();
    this.init();
  },
  methods: {
    init() {
      this.loginUserId = this.$store.state.user.userId;
      this.resetUserList();
      if (this.$store.state.user.cid) {
        this.loginUserCompanyId = this.$store.state.user.cid;
        this.search.showCompanyId = this.loginUserCompanyId;
        this.getCompanyUsers(this.loginUserCompanyId)
      }
      if (this.type === 2) {
        this.setDateToToday();
      }
    },
    setDateToToday() {
      const date_now = moment().format("YYYY-MM-DD");
      this.dateRange = [moment(date_now).subtract(0, "days").format("YYYY-MM-DD"), date_now];
      this.setDate(this.dateRange);
    },
    resetUserList() {
      this.userList = [];
      this.search.showUserId = '';
      const user = {
        id: this.loginUserId,
        label: '只看自己',
        value: this.loginUserId,
      };
      this.userList.push(user);
    },
    getCompanyUsers(id) {
      this.userList = [];
      getAllCompanyStaffById({
        id: id,
      }).then((res) => {
        if (res.data.data.users) {
          this.userList = res.data.data.users.map(item => {
            return {
              id: item.id,
              label: this.loginUserId === item.id ? '只看自己' : item.userName,
              value: item.id,
            }
          })
        }
        const item = {
          id: '',
          label: '全部用户',
          value: null,
        }
        this.userList.unshift(item);

      }).catch((err) => {
        console.error(err);
      });
    },
    getVesselByKeyword(keywords) {
      if (keywords.length < 2) {
        return
      }
      getVesselByKeyword({keywords: keywords}).then((res) => {
        this.vesselOptions = res.data.data.map(x => {
          return {
            id: x.id,
            label: x.vesselsName + '/' + x.voyage,
            value: x.id
          }
        });
      })
    },
    searchBills(bills) {
      this.search.billno = bills;
      this.go2Search();
    },
    go2Search() {
      this.$emit('search', this.search)
    },
    //下载舱单模板
    toDownload1() {
      downloadExcelModule1().then((res) => {
        if (res.status === 200) {
          this.saveFile(res.data, `上海舱单列表导入模板.xlsx`)
        }
      })
    },
    //下载舱单模板
    toDownload2() {
      downloadExcelModule2().then((res) => {
        if (res.status === 200) {
          this.saveFile(res.data, `上海舱单单票导入模板.xlsx`)
        }
      })
    },
    //导出模版数据
    exportExcel() {
      if (this.multipleSelection.length > 1) {
        this.$message({
          type: "warning",
          message: "只能选择一条舱单记录！"
        })
      } else if (this.multipleSelection.length === 1) {
        this.exportLoading = true;
        exportShipExcel(this.multipleSelection[0].id)
            .then((response) => {
              if (response.status === 200) {
                this.saveFile(response.data, `${this.multipleSelection[0].billno}${moment().format('YYYYMMDDHHmmss')}舱单.xlsx`)
              }
            }).finally(() => {
          this.exportLoading = false;
        })
      } else {
        this.$message({
          type: "warning",
          message: "请选择一条舱单记录！"
        })
      }
    },
    //导出发送记录列表
    exportHistoryExcel() {
      if (this.multipleSelection.length > 0 || (this.search.startDate && this.search.endDate)) {
        const ids = this.multipleSelection.map(item => item.id);
        const param = {
          ids: ids,
          startDate: this.search.startDate,
          endDate: this.search.endDate,
          webtype: this.search.webtype,
          vesselDateId: this.search.vesselId,
        }

        this.exportLoading = true;
        exportManifestHistoryExcel(param)
            .then((response) => {
              if (response.status === 200) {
                this.saveFile(response.data, `已发送舱单列表.xlsx`)
              }
            }).finally(() => {
          this.exportLoading = false;
        })
      } else {
        this.$message({
          type: "warning",
          message: "请选择要导出的舱单日期或勾选舱单记录！"
        })
      }
    },
    //保存文件
    saveFile(data, fileName) {
      let blob = new Blob([data], {
        type: "application/vnd.ms-excel;charset=utf-8"
      }); // res就是接口返回的文件流了
      let url = window.URL || window.webkitURL;
      let fileURL = url.createObjectURL(blob);
      let a = document.createElement("a");
      a.href = fileURL;
      a.download = fileName;
      a.target = "_self";
      a.click();
      url.revokeObjectURL(fileURL);
    },
    handleFileSend(mode) {
      this.$refs.fileSendRef.open(mode)
    },
    openManifestDialog() { //打开舱单编辑或新建仓单
      this.$emit('edit')
    },
    //上传模板导入舱单
    fileUpload1(file) {
      let filename = file.name
      let fileSuffix = filename.substr(filename.lastIndexOf('.') + 1);
      if (!['XLSX', 'XLS'].includes(fileSuffix.toUpperCase())) {
        this.$message({
          type: "warning",
          message: "只允许上传xlsx文件！"
        })
        return false
      }
      importExcelModule1({file: file.raw, id: this.$store.state.user.userId})
          .then((response) => {
            if (response.data.status) {
              this.$message({
                type: "success",
                message: "导入成功，数据已保存在草稿箱！"
              })
              this.exportDialogVisible1 = false;
              this.$emit('search', this.search);
            } else {
              this.$message.error(response.data.message);
            }
          }).finally(() => {
        this.fileList1 = []
      })
    },
    fileUpload2(file) {
      let filename = file.name
      let fileSuffix = filename.substr(filename.lastIndexOf('.') + 1);
      if (!['XLSX', 'XLS'].includes(fileSuffix.toUpperCase())) {
        this.$message({
          type: "warning",
          message: "只允许上传xlsx文件！"
        })
        return false
      }
      importExcelModule2({file: file.raw, id: this.$store.state.user.userId})
          .then((response) => {
            if (response.data.status) {
              this.$message({
                type: "success",
                message: "导入成功，数据已保存在草稿箱！"
              })
              this.exportDialogVisible2 = false;
              this.$emit('search', this.search);
            } else {
              this.$message.error(response.data.message);
            }
          }).finally(() => {
        this.fileList2 = []
      })
    },
    setDate(val) {
      if (val) {
        console.log(val)
        this.search.startDate = val[0] + ' 00:00:00'
        this.search.endDate = val[1] + ' 23:59:59'
      } else {
        this.search.startDate = '';
        this.search.endDate = '';
      }
    },
    showMultiBillDialog() {
      this.$refs.MultiBillNoDialog.init(this.search.billno);
    },
    // //批量刷新
    // batchRefresh() {
    //   if (this.multipleSelection.length > 0) {
    //     const data = [];
    //     this.multipleSelection.forEach((item) => {
    //       data.push(item.id);
    //     })
    //     agentLoadingReplay(data).then(response => {
    //       if (response.data.status) {
    //         this.$message({
    //           type: "success",
    //           message: "刷新成功"
    //         })
    //       }
    //     }).finally(() => {
    //       this.go2Search();
    //     })
    //   } else {
    //     this.$message({
    //       type: "error",
    //       message: "请选择要批量刷新的舱单！"
    //     })
    //   }
    // },
    //发送舱单
    sendManifest() {
      if (this.multipleSelection.length > 0) {
        const data = [];
        this.multipleSelection.forEach((item) => {
          data.push(item.id);
        })
        this.sendLoading = true;
        sendHyManifestShHead(data).then(response => {
          if (response.data.status) {
            this.$message({
              type: "success",
              message: "发送成功"
            })
            getUserAccountInfo();
            this.$emit('search', this.search);
          }
        }).finally(() => {
          this.sendLoading = false;
        })
      } else {
        this.$message({
          type: "error",
          message: "请选择要发送的舱单！"
        })
      }
    },
    //修改舱单
    toModify() {
      if (this.multipleSelection.length > 0) {
        if (this.multipleSelection.length > 1) {
          this.$message({
            type: "warning",
            message: "请选择一条舱单！"
          })
          return false
        }
        this.$emit('edit', this.multipleSelection[0]);
      } else {
        this.$message({
          type: "error",
          message: "请选择要修改的舱单！"
        })
      }
    },
    //删除舱单
    toDelete(type = 0) {
      if (this.multipleSelection.length > 0) {
        // if (type === 1 && this.multipleSelection.length > 1) {
        //   this.$message({
        //     type: "warning",
        //     message: "请选择一条舱单！"
        //   })
        //   return false
        // }
        const deleteData = [];
        this.multipleSelection.forEach((item) => {
          deleteData.push(item);
        })
        this.$nextTick(() => {
          this.$refs.DeleteManifestDialog.init(deleteData, type);
        })
      } else {
        this.$message({
          type: "error",
          message: "请选择要删除的舱单！"
        })
      }
    },
    getShipAgentList() {
      getShipAgentList().then(({data}) => {
        this.shipAgent = data.data.map(x => {
          return {
            id: x.id,
            label: x.name,
            value: x.code,
          }
        });
      })
    },
    showErrorList(data) {
      console.log("showErrorList==", data)
      this.$nextTick(() => {
        this.$refs.DeleteManifestErrorDialog.init(data);
      })
    },
    formatBills(val) {
      this.search.billno = val.trim().replace(' ', ',').replace(/[^a-zA-Z0-9,，]/g, '').toUpperCase();
    },
  }
}
</script>


<style scoped lang="stylus">
.input_div {
  //padding:1rem;
  margin: 1rem 0;
  //border-top: 1px solid var(--GRAY-f1f3f6);
  display: flex;
  justify-content: space-between;
  background: var(--GRAY-f1f3f6);

  .inline-input {
    margin-right: 1rem;
    width: 20rem;
  }
}

.small-input {
  margin-right: 1rem;
  width: 12rem;
}

.el_select {
  margin-right: 1rem;
  width: 12rem;
  display: block;
}

.export_module {
  display: flex;
  justify-content: center;
  align-items: center;
  //padding: 2rem;
  //border: 2px dashed var(--GRAY-dcdcdc);
  //border-radius: 4px;
}

.el-date-editor--daterange.el-input__inner {
  width: 240px;
}

.bill_search_div {
  display: flex;
  align-items: center;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  background: white;
  padding: 0 6px;
  box-sizing: border-box;
  margin-right: 1rem;

  .inline-input {
    margin: 0;
    width: 18rem;
  }

  /deep/ .el-input__inner {
    height: 34px;
    border: none !important;
    padding: 0 30px 0 0 !important;
  }
}

.multi_bill_div {
  cursor: pointer;
  font-size: 12px;
  color: var(--BLUE-409eff);
  padding: 2px;
  box-sizing: border-box;
  margin: 4px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%;
  border-image-source: radial-gradient(50% 50%, transparent 10px, transparent 100%, var(--BLUE-409eff) 50%);
  border-image-slice: 1;
  border-width: 1px;
  border-style: solid;
}
</style>
