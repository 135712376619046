<template>
    <el-select v-model="curShipAgent" placeholder="请选择船代" filterable style="width:100%">
        <el-option
            v-for="item in totalShipAgent"
            :key="item.value"
            :label="item.label"
            :value="item.label"
        ></el-option>
    </el-select>
</template>

<script>
export default {
    name: 'ShipAgentSelect',
    model: {
        prop: 'shipagent',
        event: 'change'
    },
    props: {
        shipagent: String
    },
    computed: {
        curShipAgent: {
            get() { 
                return this.shipagent
            },
            set(val) { 
                this.$emit('change',val)
            }
        },
        totalShipAgent() { 
            return this.$store.state.txtdata.shipAgent
        }
    },
    mounted() { 
        if (!this.totalShipAgent.length) { 
            this.$store.dispatch('txtdata/updateShipAgent')
        }
    }
}
</script>