<template>
  <el-container id="the_button" v-loading="dataLoading"
                element-loading-text="数据加载中"
                element-loading-background="rgba(255, 255, 255, 0.5)">
    <div class="the_button" :class="needFixed?'fixed':''">
      <div class="user_remark">备注：
        <span @click="addRemark"><i class="el-icon-edit"></i>{{ detail.head.remark ? detail.head.remark : '添加备注' }}</span>
      </div>
      <div>

        <el-button type="primary" @click="modifyData" size="small" :loading="saveLoading" v-if="isModify">
          修改报文
        </el-button>
        <el-button type="warning" @click="ReissueData" size="small" :loading="saveLoading" v-if="isReissue">
          重发报文
        </el-button>
        <el-button type="primary" @click="save2Wait()" size="small" :loading="saveLoading"
                   v-if="!this.baseForm.completionstatus||[0,1].includes(this.baseForm.completionstatus)">
          保存到待发送
        </el-button>
<!--        <el-button type="warning" @click="saveAndSend" size="small" :loading="saveLoading"-->
<!--                   v-if="isSend&&!isReissue&&!isModify">-->
<!--          保存并发送-->
<!--        </el-button>-->
        <el-button type="primary" plain @click="save2Draft()" size="small" :loading="saveLoading"
                   v-if="!this.baseForm.completionstatus||[0,1].includes(this.baseForm.completionstatus)">
          保存到草稿箱
        </el-button>
        <el-button plain size="small" @click="close" :loading="saveLoading">关闭</el-button>
      </div>
    </div>
    <div>
      <BaseFormModule :item="baseForm" ref="baseForm" @goodsType="goodsTypeChange"
                      :is-modify="isModify" :is-reissue="isReissue"></BaseFormModule>
      <ShipFormModule :item="baseForm" ref="shipForm"
                      :is-modify="isModify" :is-reissue="isReissue"
                      @checkShipAgentInfo="checkShipAgent"></ShipFormModule>
      <SFTFormModule ref="consignerForm" :item="consignerForm" :type-val="{title:'发货人',code:0}"
                     :countryList="countryList" @syncRSN="getSFTContactDetail"></SFTFormModule>
      <SFTFormModule ref="consigneeForm" :item="consigneeForm" :type-val="{title:'收货人',code:1}"
                     @syncNotice="syncNotice"
                     :countryList="countryList"></SFTFormModule>
      <SFTFormModule ref="informersForm" :item="informersForm" :type-val="{title:'通知人',code:2}"
                     :countryList="countryList"></SFTFormModule>
      <div class="the_title">货物明细</div>
      <MainfestGoodsTable ref="ManifestGoodsTable" :item="goodsList" :select-goods-type="selectGoodsType"
                          :is-modify="isModify" :is-reissue="isReissue"
                          @itemsDeleted="refreshGoodsList"></MainfestGoodsTable>
      <div>
        <div class="the_title2">{{ isVGMLook ? '舱单已发送，如要修改请到VGM页面' : 'VGM' }}</div>
        <AddVGMDialog
            :is-manifest-sub="true"
            :is-look="isVGMLook"
            @autoVGM="createVGM"
            ref="ManifestVGM"
        ></AddVGMDialog>
      </div>
    </div>
    <CheckBillNosDialog ref="CheckBillNosDialog" @confirm="checkVesselsAndVoyageInfo"></CheckBillNosDialog>
    <ShipAgentCheckDialog ref="shipAgentCheckDialog" @select="selectShipAgent" @sure="onSave(1)"></ShipAgentCheckDialog>
    <VesselsVoyageCheckDialog ref="vesselsVoyageCheckDialog" @sure="checkShipAgent(1)"
                              @changeVoyage="changeVoyage"></VesselsVoyageCheckDialog>
    <!-- 弹窗, 修改备注 -->
    <remark-box-dialog
        :show-tab="showTab"
        ref="RemarkBoxDialog"
        @refreshDataList="showRemark"
    ></remark-box-dialog>
  </el-container>
</template>

<script>
import {
  getAllCountryCode,
  saveHead,
  getDetailById,
  saveAndResend, saveAndReissue, checkVesselsVoyageInfo, applyAgentUpdate, applyAgentReissue, checkShipAgentInfo,
} from "@/api/shanghaiManifest";
import DialogVue from "@/components/common/Dialog.vue";
import CheckBillNosDialog from "@/views/ShanghaiManifest/components/CheckBillNosDialog.vue";
import SFTFormModule from "@/views/ShanghaiManifest/components/sft/SFTFormModule.vue";
import MainfestGoodsTable from "@/views/ShanghaiManifest/components/goods/ManifestGoodsTable.vue";
import BaseFormModule from "@/views/ShanghaiManifest/components/baseInfo/BaseFormModule.vue";
import ShipFormModule from "@/views/ShanghaiManifest/components/shipInfo/ShipFormModule.vue";
import {goodsType} from "@/data/manifestInformation";
import VesselsVoyageCheckDialog from "@/views/ShanghaiManifest/components/VesselsVoyageCheckDialog.vue";
import ShipAgentCheckDialog from "@/views/ShanghaiManifest/components/ShipAgentCheckDialog.vue";
import AddVGMDialog from "@/views/VGM/components/AddVGMDialog.vue";
import bus from "@/utils/bus";
import RemarkBoxDialog from "@/views/ShanghaiManifest/components/ManifestRemarkDialog.vue";

export default {
  props: {
    item: {//列表传入的item
      type: Object,
      default: () => {
      }
    },
    index: {
      type: String,
      default: "0", //default 默认值，父组件不传值就显示默认
    },
    list: {
      type: Object,
      default: () => {
      },
    },
  },
  components: {
    RemarkBoxDialog,
    AddVGMDialog,
    ShipAgentCheckDialog,
    VesselsVoyageCheckDialog,
    ShipFormModule,
    BaseFormModule,
    MainfestGoodsTable,
    SFTFormModule,
    DialogVue,
    CheckBillNosDialog,
  },
  watch: {
    vgmDetail(newVal) {
      return newVal;
    }
  },
  computed: {
    needFixed() {
      if (this.scrollTop > 0) {
        return this.scrollTop >= this.offsetTop - 70;
      } else {
        return false;
      }
    },
  },
  data() {
    return {
      detail: {
        head: {},
        contacts: [],
        goods: []
      },
      baseForm: {},
      //发货人信息
      consignerForm: {},
      //收货人
      consigneeForm: {},
      //通知人
      informersForm: {},
      //货物明细
      goodsList: [],
      //港口列表
      portsList: [],//港口原数据
      portOptions: [],//下拉框的港口数据
      countryList: [],
      selectGoodsType: goodsType[0].value,//选择的货物类型
      showCheckBillNosResultDialog: false,
      saveLoading: false,
      dataLoading: false,
      offsetTop: 0,
      scrollTop: 0,
      isVGMLook: false,//如果舱单已经发送，详情页只能查看VGM
      isModify: false,//如果舱单已经发送，则只能修改部分数据
      isReissue: false,//如果舱单已经删除，可以修改除提单号外的其他数据，进行重发
      isSend: false,//如果舱单已发送，还没有拿到回执只能修改船司船代并只能用
      vgmDetail: {},//VGM数据
      showTab: "Manifest",
      remark: '',
    };
  },
  methods: {
    init() {
      this.offsetTop = document.getElementById("the_button").getBoundingClientRect().top;
      window.addEventListener('scroll', this.handleScroll)
      this.getCountryList();
      const params = this.$route.params
      if (params && params.id) {
        this.getDetail(params.id)
      } else {
        // create form
      }
    },
    getDetail(id) {
      this.dataLoading = true;
      getDetailById({id: id})
          .then((response) => {
            this.detail = response.data.data;
            this.baseForm = this.detail.head;
            if (this.baseForm) {
              if (this.baseForm.completionstatus === 2) {
                this.isVGMLook = true;
                this.isSend = true;
              }
              if (this.baseForm.completionstatus === 2 && this.baseForm.shipAgentBillStatus === 1) {
                this.isModify = true;
              } else if (this.baseForm.completionstatus === -2 || this.baseForm.completionstatus === -3) {
                this.isReissue = true;
              }
            }
            if (this.baseForm) {
              this.goodsTypeChange(this.baseForm.goodtype);
            }
            if (this.detail.vgm) {
              this.vgmDetail = this.detail.vgm;
              bus.$emit('refreshVGM', this.vgmDetail);
            }
            this.consignerForm = this.detail.contacts.filter(x => x.type === 0)[0];
            this.consigneeForm = this.detail.contacts.filter(x => x.type === 1)[0];
            this.informersForm = this.detail.contacts.filter(x => x.type === 2)[0];
            this.goodsList = this.detail.goods
            // console.log(response);
          }).finally(() => {
        this.dataLoading = false;
      })
    },
    //status，0：草稿箱，1：待发送，2：已发送
    save2Draft() {
      let list = [];
      list.push(
          this.$refs.baseForm.validate(),
      );
      Promise.all(list)
          .then(() => {
            this.onSave(0)
          }).catch((err) => {
        this.$message({type: 'error', message: err})
      })

    },
    save2Wait(status = 1) {
      let list = [];
      list.push(
          this.$refs.baseForm.validate(),
          this.$refs.shipForm.validate(),
          this.$refs.consignerForm.validate(),
          this.$refs.consigneeForm.validate(),
          this.$refs.informersForm.validate(),
          this.$refs.ManifestGoodsTable.validate(),
      );
      if (this.$refs.ManifestVGM.$refs.baseForm && this.$refs.ManifestVGM.$refs.VGMContainerTable) {
        list.push(
            this.$refs.ManifestVGM.$refs.baseForm.validate(),
            this.$refs.ManifestVGM.$refs.VGMContainerTable.validate(),
        );
      }
      Promise.all(list)
          .then(() => {
            if (this.checkBillNos(status)) {
              this.checkVesselsAndVoyageInfo(status);
            }
          }).catch((err) => {
        this.$message({type: 'error', message: err})
      })
    },
    changeVoyage(entity) {
      this.$refs.shipForm.changeVoyage(entity);
    },
    checkShipAgent(status = 0) {
      if (!this.$refs.shipForm.shipForm.vesselname || !this.$refs.shipForm.shipForm.voyageno || !this.$refs.baseForm.form.scompany) {
        this.$message.error("请输入船名，航次和船公司")
        return false;
      } else if (this.$refs.shipForm.shipForm.voyageno.includes('V.') || this.$refs.shipForm.shipForm.voyageno.includes('v.')) {
        this.$message.error("航次不能有V.")
        return false;
      }
      const param = {
        vesselName: this.$refs.shipForm.shipForm.vesselname,
        voyageNo: this.$refs.shipForm.shipForm.voyageno,
        carrierName: this.$refs.baseForm.form.scompany,
        billNo: this.$refs.baseForm.form.billno,
      }

      checkShipAgentInfo(param).then((res) => {
        if (res.data.status) {
          if (status !== 0 && res.data.data && res.data.data.length === 1 && res.data.data[0].shipCompanyValue === this.$refs.baseForm.form.scompany && res.data.data[0].shipAgencyValue === this.$refs.shipForm.shipForm.webtype) {
            this.onSave(status);
          } else {
            this.$refs.shipAgentCheckDialog.init(res.data.data, status === 1, {
              shipCompanyValue: this.$refs.baseForm.form.scompany,
              shipAgencyValue: this.$refs.shipForm.shipForm.webtype,
            });
          }
        }
      }).finally(() => {
      })
    },
    //保存并发送
    saveAndSend() {

    },
    //修改报文
    modifyData() {
      this.save2Wait(2);
    },
    //重发报文
    ReissueData() {
      this.save2Wait(3);
    },
    //校验分提单号是否符合主题单号
    checkBillNos(status = 1) {
      const billNo = this.$refs.baseForm.form.billno;
      const goods = this.$refs.ManifestGoodsTable.form.goodsList;
      let canConfirm = true;//弹窗显示确认无误按钮
      //查找是否有和主提单号一样的分提单号
      let same_goods = goods.filter((item) => item.billno === billNo);
      //查找是否有不属于主提单号的分提单号,依靠两种字符串相似的比较判断主分单号的关联性，测试下来jac在低于0.66，leve高于6就关联性不高
      let error_goods = goods.filter((item) => {
            const similarity_jac = this.jaccard(billNo, item.billno);
            const similarity_leve = this.levenshtein(billNo, item.billno);
            if (similarity_jac > 0.6 && similarity_jac <= 0.66 || similarity_leve >= 6 && similarity_leve <= 7) {
              canConfirm = true;
            } else {
              canConfirm = false;
            }

            return similarity_jac < 0.66 || similarity_leve >= 6
          }
      );
      if (same_goods.length === 0) {
        this.$refs.CheckBillNosDialog.init(billNo);
        return false;
      }
      if (error_goods.length >= 1) {
        this.$refs.CheckBillNosDialog.init(billNo, error_goods, canConfirm, status);
        return false;
      } else {
        return true;
      }
    },
    //Jaccard比较两个字符串的相似度
    jaccard(s1, s2) {
      const set1 = new Set(s1);
      const set2 = new Set(s2);
      const intersection = [...set1].filter((item) => set2.has(item)).length;
      const union = new Set([...set1, ...set2]).size;
      // console.log("主提单号：" + s1 + "分提单号：" + s2);
      // console.log("jaccard 主分提单号相似度：" + intersection / union);
      return intersection / union;
    },
    //Levenshtein 判断字符串相似度，衡量一个字符串转换为另一个字符串的最少操作
    levenshtein(s1, s2) {
      const matrix = [];
      for (let i = 0; i <= s1.length; i++) {
        matrix[i] = [i];
      }
      for (let j = 0; j <= s2.length; j++) {
        matrix[0][j] = j;
      }

      for (let i = 1; i <= s1.length; i++) {
        for (let j = 1; j <= s2.length; j++) {
          if (s1[i - 1] === s2[j - 1]) {
            matrix[i][j] = matrix[i - 1][j - 1];
          } else {
            matrix[i][j] = Math.min(
                matrix[i - 1][j - 1] + 1,//Replace
                matrix[i][j - 1] + 1,//Insert
                matrix[i - 1][j] + 1//Delete
            )
          }
        }
      }
      // console.log("主提单号：" + s1 + "分提单号：" + s2);
      // console.log("levenshtein 主分提单号相似度：" + matrix[s1.length][s2.length]);
      return matrix[s1.length][s2.length];
    },
    /**
     * 查询船名航次是否存在
     * @returns {boolean}
     */
    checkVesselsAndVoyageInfo(status = 1) {
      if (!this.$refs.shipForm.shipForm.vesselname || !this.$refs.shipForm.shipForm.voyageno) {
        this.$message.error("请输入船名和航次")
        return false;
      }
      let param = {
        vessels: this.$refs.shipForm.shipForm.vesselname,
        voyage: this.$refs.shipForm.shipForm.voyageno,
      }
      this.checkVesselsVoyageInfoLoading = true;

      let curr_ships = null;
      checkVesselsVoyageInfo(param).then((res) => {
        if (res.data.status) {
          curr_ships = res.data.data;
        }
      }).finally(() => {
        this.checkVesselsVoyageInfoLoading = false;
        if (curr_ships && curr_ships.length === 1 && curr_ships[0].vesselsName === param.vessels && curr_ships[0].voyage === param.voyage) {
          this.checkShipAgent(status)
        } else {
          this.$refs.vesselsVoyageCheckDialog.init(param, curr_ships);
        }
      })
    },
    onSave(status = 0) {
      this.saveLoading = true;
      this.baseForm = {...this.$refs.shipForm.shipForm, ...this.$refs.baseForm.form}
      this.baseForm.completionstatus = status === 3 ? 2 : status;
      //如果是企业身份需要传企业ID和名称
      this.baseForm.companyid = this.$store.getters.cid;
      this.baseForm.companyname = this.$store.getters.loginId;
      this.baseForm.totalpackno = this.$refs.ManifestGoodsTable.AllPackage;
      this.baseForm.totalvolume = this.$refs.ManifestGoodsTable.AllVolume;
      this.baseForm.totalweight = this.$refs.ManifestGoodsTable.AllWeight;
      let vgmDetail = this.$refs.ManifestVGM.getVGMDetail();
      if (vgmDetail) {
        vgmDetail.bizVgmEntity.completionstatus = status;
        vgmDetail.bizVgmEntity.shipCompany = this.baseForm.scompany;
        vgmDetail.bizVgmEntity.billNo = this.baseForm.billno;
        vgmDetail.bizVgmEntity.vessel = this.baseForm.vesselname;
        vgmDetail.bizVgmEntity.voyage = this.baseForm.voyageno;
        vgmDetail.bizVgmEntity.companyId = this.$store.getters.cid;
        vgmDetail.bizVgmEntity.companyName = this.$store.getters.loginId;
      }
      this.detail = {
        head: this.baseForm,
        contacts: [this.$refs.consigneeForm.form, this.$refs.consignerForm.form, this.$refs.informersForm.form],
        goods: this.$refs.ManifestGoodsTable.form.goodsList,
        vgm: vgmDetail,
      }

      const request = status === 3 ? applyAgentReissue : status === 2 ? applyAgentUpdate : saveHead;

      request(this.detail).then((response) => {
        if (response.data.status) {
          this.$message({
            type: "success",
            message: "保存成功！",
          });
          this.$router.back();
        } else {
          this.$message({
            type: "error",
            message: "保存失败！",
          });
        }
      }).finally(() =>
          this.saveLoading = false);
    },
    close() {
      this.$router.back();
    },
    //根据舱单数据自动生成VGM数据
    createVGM() {
      let containerList = this.$refs.ManifestGoodsTable.form.goodsList.map(item => {
        return {
          ctnNo: item.ctnno,
          vgmMethod: 'SM2',
          vgmNetWeight: '',
          vgmTareWeight: '',
          vgmGrossWeight: '',
          vgmLocation: this.$refs.consignerForm.form.name,
          vgmAddress: '',
          vgmSign: '',
        }
      });
      const map = new Map();
      let ctnList = containerList.filter(v => !map.has(v.ctnNo) && map.set(v.ctnNo, v));
      this.$nextTick(() => {
        this.vgmDetail.bizVgmEntity = {
          vessel: this.$refs.shipForm.shipForm.vesselname, //船公司
          voyage: this.$refs.shipForm.shipForm.voyageno, //船公司
          shipCompany: this.$refs.baseForm.form.scompany, //船公司
          portType: "1", //港口，0：宁波，1：上海
          ctnSocMark: "N", //货主箱标志，Y=货主箱N=非货主箱
        }
        this.vgmDetail.bizVgmContainerEntityList = ctnList;
      })

      bus.$emit('refreshVGM', this.vgmDetail);
    },
    //获取国家代码列表
    getCountryList() {
      getAllCountryCode().then(({data}) => {
        this.countryList = data.data.map(x => {
          return {
            id: x.id,
            value: x.code + '-' + x.name,
            label: x.code + '-' + x.name
          }
        });
      });
    },
    refreshGoodsList(newList) {
      this.goodsList = newList
    },
    syncNotice(item) {
      const data = JSON.parse(JSON.stringify(item));
      this.$refs.informersForm.syncByRevice(data)
    },
    getSFTContactDetail(data) {
      this.$refs.informersForm.getContactDetail(data.noticeId);
      this.$refs.consignerForm.getContactDetail(data.sendId);
      this.$refs.consigneeForm.getContactDetail(data.reviceId);

    },
    goodsTypeChange(type) {
      this.selectGoodsType = type;
    },
    handleScroll() {
      this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      // console.log('scroll', this.scrollTop);
      // console.log('topOffset', this.offsetTop);
    },
    selectShipAgent(item) {
      this.$refs.baseForm.form.scompany = item.shipCompanyValue;
      this.$refs.shipForm.shipForm.webtype = item.shipAgencyValue;
    },
    //添加备注
    addRemark() {
      this.$nextTick(() => {
        this.$refs.RemarkBoxDialog.init({
          id: this.detail.head.id,
          blno: this.detail.head.billno,
          remark: this.detail.head.remark
        });
      });
    },
    showRemark(remark) {
      this.detail.head.remark = remark;
    }
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  //自定义指令
  directives: {
    'el-select-loadmore': (el, binding) => {
      // 获取element-ui定义好的scroll盒子
      const SELECTWRAP_DOM = el.querySelector(".el-select-dropdown .el-select-dropdown__wrap");
      if (SELECTWRAP_DOM) {
        SELECTWRAP_DOM.addEventListener("scroll", function () {
          /**
           * scrollHeight 获取元素内容高度(只读)
           * scrollTop 获取或者设置元素的偏移值,
           *  常用于:计算滚动条的位置, 当一个元素的容器没有产生垂直方向的滚动条, 那它的scrollTop的值默认为0.
           * clientHeight 读取元素的可见高度(只读)
           * 如果元素滚动到底, 下面等式返回true, 没有则返回false:
           * ele.scrollHeight - ele.scrollTop === ele.clientHeight;
           */
          const condition = this.scrollHeight - this.scrollTop <= this.clientHeight;
          if (condition) binding.value();
        });
      }
    },
  }
};
</script>

<style lang="stylus" scoped>
#the_button {
  display: flex;
  flex-direction: column;
}

.the_button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  padding: 1rem;
  box-shadow: 7px 2px 10px 0 var(--GRAY-dcdcdc);
}

.dialog-footer {
  width: 50%;
  display: flex;
  justify-content: space-between;
  margin: 30px auto 0;
}

.the_title {
  user-select: none;
  cursor: pointer;
  padding: 0.6rem 0;
  font-size: 16px;
  text-align: center;
  background: var(--GRAY-dcdcdc);
  margin-bottom: 1rem;
}

.the_title2 {
  user-select: none;
  cursor: pointer;
  padding: 0.6rem 0;
  font-size: 16px;
  text-align: center;
  background: var(--GRAY-dcdcdc);
  margin: 1rem 0;
}

.vgm_div {
  display: flex;
  flex-direction: column;
  margin: 0 auto 2rem;

  i {
    margin: 0 0.5rem;
    color: var(--YELLOW-ed6a0c);
  }
}

.el-icon-arrow-down, .el-icon-arrow-up {
  cursor: pointer;
}

.fixed {
  position: fixed;
  top: 70px;
  right: 0;
  width: calc(90% - 20px);
  z-index: 10;
}

.user_remark {
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
}

</style>
